function getBoxCoords(W, H, X, Y, from, h) {
  var box={};
  if (from=='top') {
    box.X=X;
    box.Y=Y;
    box.W=W;
    box.H=h*H;
  } else if (from=='bottom') {
    box.X=X;
    box.W=W;
    box.H=h*H;
    box.Y=Y+(H-box.H);
  } else if (from=='middle') {
    box.X=X;
    box.W=W;
    box.H=h*H;
    box.Y=0.5-box.H/2;
  } else {
    box.X=X;
    box.W=W;
    box.H=h*H;
    box.Y=Y+from*H;
  }

  return box;

}
function splitBox(w, h, x, y, vOrH, s, reverse=0) {
  var boxes={box1:{}, box2:{}};
  if (!reverse) {
    if (vOrH==1) { 
      boxes.box1.W=w*s;
      boxes.box1.H=h;
      boxes.box1.X=x;
      boxes.box1.Y=y;
      boxes.box2.W=w*(1-s);
      boxes.box2.H=h;
      boxes.box2.X=x+w*s;
      boxes.box2.Y=y;
    } else {
      boxes.box1.W=w;
      boxes.box1.H=h*s;
      boxes.box1.X=x;
      boxes.box1.Y=y;
      boxes.box2.W=w;
      boxes.box2.H=h*(1-s);
      boxes.box2.X=x;
      boxes.box2.Y=y+h*s;
    }
  } else {
    if (vOrH==1) { 
      boxes.box2.W=w*s;
      boxes.box2.H=h;
      boxes.box2.X=x;
      boxes.box2.Y=y;
      boxes.box1.W=w*(1-s);
      boxes.box1.H=h;
      boxes.box1.X=x+w*s;
      boxes.box1.Y=y;
    } else {
      boxes.box2.W=w;
      boxes.box2.H=h*s;
      boxes.box2.X=x;
      boxes.box2.Y=y;
      boxes.box1.W=w;
      boxes.box1.H=h*(1-s);
      boxes.box1.X=x;
      boxes.box1.Y=y+h*s;
    }



  }
  return boxes;
}


function combineArrays(arr1, arr2) {
  let result = [];

  for (let i = 0; i < arr1.length; i++) {
    for (let j = 0; j < arr2.length; j++) {
      result.push([arr1[i], arr2[j]]);
    }
  }

  return result;
}

function getMinMaxLen(text) {

  var words=text.split(' ');
  var index=0;
  var maxLen=0;
  var minLen=100;
  for (let i = 0; i < words.length; i++) {
    if (words[i].length>maxLen) {maxLen=words[i].length;index=i;}
    if (words[i].length<minLen) {minLen=words[i].length;}

  }


  return [minLen, maxLen];

}

function getNumAuthors(au) {


au=(au.split( /,/ ).reduce( (a,b, i) => i %2 == 0 ? a + ";" + b : a + "," + b ) );
var authors=au.split(';');
const numAuthors=authors.length;
var authors2=[];
authors.forEach(au => {
  var temp=au.split(',');

  var auTemp='';
  if (temp[1]) {auTemp=temp[1].trim()+' '+temp[0].trim()} else {auTemp=temp[0].trim()}
  authors2.push(auTemp.trim());

});
au=authors2.join(', ');
return [au, numAuthors];

}

export default function genDesign(title,canvasWidth, canvasHeight,orientation,numElements, cluster) {
  var design={};


  const fontCombos=[
    {id:"CormonrantGaramond", tiFont: "Cormorant Garamond", stiFont:"Cormorant Garamond", auFont:"Cormorant Garamond"},
    {id:"MontessaratFiraCode", tiFont: "Montserrat", stiFont:"Fira Code", auFont:"Fira Code"},
     {id:"OswaldQuattrocento", tiFont: "Oswald", stiFont:"Quattrocento", auFont:"Quattrocento"},
     {id:"cyborg", tiFont: "Roboto", stiFont:"Roboto", auFont:"Roboto"},
     {id: "LoraRoboto", tiFont: "Lora", stiFont:"Roboto", auFont:"Roboto"},
     {id: "PlayfairDisplaySourceSansPro", tiFont: "Playfair Display", stiFont:"Source Sans Pro", auFont:"Source Sans Pro"},
     {id: "Neucha", tiFont: "Neucha", stiFont:"Neucha", auFont:"Neucha"},
     {id: "News", tiFont: "News Cycle", stiFont:"News", auFont:"News"},
     {id: "lux", tiFont: "Nunito Sans", stiFont:"Nunito Sans", auFont:"Nunito Sans"},
     {id: "sketch", tiFont: "'Cabin Sketch', cursive", stiFont:"Neucha", auFont:"Neucha"},
     {id: "expletus", tiFont: "Expletus Sans", stiFont:"Hind", auFont:"Hind"},
      {id: "Bubblegum", tiFont: "Bubblegum Sans", stiFont:"Open Sans", auFont:"Open Sans"},
      {id: "Mulish", tiFont: "Mulish", stiFont:"Space Mono", auFont:"Space Mono"},
      
   ];


  design.canvasWidth=canvasWidth;
  design.canvasHeight=canvasHeight;
  
  design.ti=title.title.trim();
  design.sti=title.subtitle.trim();
  design.au=title.au.trim();



var boxLayout={ti:{}, sti:{}, au:{}};

const layoutLibraries=[
  {layout: "loneRanger", layoutDetail: { tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'center', tiD: 'down', stiD:'down', auD:'up', W: 0.9 , H: 0.99,tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular',
    options: {
      '1line_smsti': { tiA: {h: 0.2, from: 'top'}, stiA: {h: 0.04, from: 0.9}, auA: {h: 0.05, from: 'bottom'},  tiPadding: 0.05, stiPadding: 0.02, auPadding: 0, maxTiNumLines: 1},
      '1line_lgsti': {tiA: {h: 0.2, from: 'top'}, stiA: {h: 0.12, from: 0.82}, auA: {h: 0.05, from: 0.94}, tiPadding: 0.05, stiPadding: 0.2, auPadding: 0, maxTiNumLines: 1},
      '1line_mdsti': {tiA: {h: 0.2, from: 'top'}, stiA: {h: 0.07, from: 0.87}, auA: {h: 0.05, from: 0.94}, tiPadding: 0.05, stiPadding: 0.2, auPadding: 0, maxTiNumLines: 1},
      '2line_mdsti': {tiA: {h: 0.2, from: 'top'}, stiA: {h: 0.07, from: 0.87}, auA: {h: 0.05, from: 0.94}, tiPadding: 0.1, stiPadding: 0.2, auPadding: 0, maxTiNumLines: 2},
      '2line_smsti': {tiA: {h: 0.2, from: 'top'}, stiA: {h: 0.04, from: 0.91}, auA: {h: 0.04, from: 0.95}, tiPadding: 0.1, stiPadding: 0.2, auPadding: 0, maxTiNumLines: 2},
      '2line_lgsti': {tiA: {h: 0.2, from: 'top'}, stiA: {h: 0.12, from: 0.82}, auA: {h: 0.05, from: 0.94}, tiPadding: 0.1, stiPadding: 0.2, auPadding: 0, maxTiNumLines: 2},
    },
    clusterMap: {
    '16':'1line_smsti',
'83':'1line_mdsti',
'41':'1line_mdsti',
'8':'1line_mdsti',
'38':'2line_smsti',
'56':'2line_smsti',
'51':'2line_mdsti',
'17':'1line_lgsti',
'90':'1line_mdsti',
'12':'2line_smsti',
'24':'1line_mdsti',
'31':'1line_smsti',
'9':'1line_smsti',
'98':'1line_mdsti',
'80':'2line_smsti',
'44':'2line_smsti',
'28':'2line_mdsti',
'46':'2line_mdsti',
'78':'2line_mdsti',
'82':'2line_mdsti',
'2':'2line_smsti',
'76':'2line_mdsti',
'49':'1line_smsti',
'47':'2line_lgsti',
'96':'2line_smsti',
'62':'2line_smsti',
'87':'2line_mdsti',
'30':'2line_smsti',
'3':'2line_mdsti',
'66':'2line_mdsti',
'91':'2line_lgsti',
'54':'2line_mdsti',
'21':'2line_smsti',
'60':'2line_lgsti',
'11':'2line_mdsti',
'5':'2line_lgsti',
'70':'1line_mdsti',
'67':'2line_smsti',
'64':'1line_mdsti',
'81':'2line_smsti',
'77':'1line_mdsti',
'69':'2line_mdsti',
'1':'2line_mdsti',
'84':'2line_lgsti',
'13':'2line_lgsti',
'10':'2line_lgsti',
'59':'2line_mdsti',
'15':'2line_lgsti',
'7':'2line_smsti',
'6':'2line_mdsti',
'45':'2line_lgsti',
'68':'2line_lgsti',
'35':'2line_lgsti',
'73':'2line_smsti',
'50':'2line_smsti',
'26':'1line_lgsti',
'25':'2line_lgsti',
'71':'2line_smsti',
'19':'2line_lgsti',
'72':'2line_lgsti',
'40':'2line_smsti',
'43':'2line_mdsti',
'36':'2line_lgsti',
'75':'2line_lgsti',
'52':'2line_lgsti',
'32':'2line_lgsti',
'57':'2line_smsti',
'74':'1line_smsti',
'99':'2line_lgsti',
'23':'2line_lgsti',
'0':'2line_lgsti',
'86':'2line_lgsti',
'34':'1line_lgsti',
'4':'2line_lgsti',
'14':'2line_lgsti',
'48':'2line_mdsti',
'18':'2line_lgsti',
'65':'2line_lgsti',
'95':'2line_mdsti',
'85':'2line_mdsti',
'79':'2line_smsti',
'58':'2line_lgsti',
'27':'1line_lgsti',
'97':'2line_smsti',
'92':'1line_mdsti',
'42':'2line_mdsti',
'22':'2line_smsti',
'55':'2line_lgsti',
'39':'2line_lgsti',
'93':'2line_lgsti',
'88':'2line_lgsti',
'29':'2line_mdsti',
'33':'2line_lgsti',
'37':'2line_smsti',
'20':'2line_lgsti',
'53':'2line_lgsti',
'89':'2line_lgsti',
'61':'2line_lgsti',
'63':'2line_lgsti',
'94':'2line_lgsti',  
    },
  },
},
{layout: "basicleft", layoutDetail: { tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'left', tiD: 'up', stiD:'down', auD:'up', W: 0.8 , H: 0.9, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular',
  options: {
    '1line': { tiA: {h: 0.1, from: 'top'}, stiA: {h: 0.04, from: 0.1}, auA: {h: 0.05, from: 'bottom'},tiPadding: 0.01, stiPadding: 0.01, auPadding: 0, maxTiNumLines: 1},
    '2line': {tiA: {h: 0.15, from: 'top'}, stiA: {h: 0.04, from: 0.15},auA: {h: 0.05, from: 'bottom'}, tiPadding: 0.01, stiPadding: 0.01, auPadding: 0, maxTiNumLines: 2},
  },
  clusterMap: {
    '16':'1line',
'83':'1line',
'41':'1line',
'8':'1line',
'38':'2line',
'56':'1line',
'51':'2line',
'17':'1line',
'90':'1line',
'12':'2line',
'24':'1line',
'31':'1line',
'9':'1line',
'98':'1line',
'80':'2line',
'44':'2line',
'28':'2line',
'46':'2line',
'78':'1line',
'82':'2line',
'2':'2line',
'76':'2line',
'49':'1line',
'47':'1line',
'96':'2line',
'62':'2line',
'87':'2line',
'30':'2line',
'3':'1line',
'66':'2line',
'91':'2line',
'54':'2line',
'21':'2line',
'60':'2line',
'11':'2line',
'5':'2line',
'70':'1line',
'67':'2line',
'64':'1line',
'81':'2line',
'77':'1line',
'69':'2line',
'1':'2line',
'84':'2line',
'13':'2line',
'10':'2line',
'59':'2line',
'15':'2line',
'7':'1line',
'6':'2line',
'45':'2line',
'68':'2line',
'35':'1line',
'73':'2line',
'50':'1line',
'26':'1line',
'25':'2line',
'71':'1line',
'19':'2line',
'72':'2line',
'40':'1line',
'43':'2line',
'36':'2line',
'75':'2line',
'52':'2line',
'32':'1line',
'57':'2line',
'74':'1line',
'99':'1line',
'23':'2line',
'0':'2line',
'86':'1line',
'34':'1line',
'4':'2line',
'14':'1line',
'48':'2line',
'18':'2line',
'65':'2line',
'95':'1line',
'85':'2line',
'79':'2line',
'58':'2line',
'27':'1line',
'97':'2line',
'92':'1line',
'42':'2line',
'22':'2line',
'55':'2line',
'39':'2line',
'93':'2line',
'88':'2line',
'29':'2line',
'33':'2line',
'37':'2line',
'20':'2line',
'53':'2line',
'89':'2line',
'61':'2line',
'63':'2line',
'94':'1line',
  },
},
},
{layout: "highright", layoutDetail: { tiCase: 'upper', stiCase: 'proper', auCase: 'upper', align: 'right', tiD: 'up', stiD:'down', auD:'up', W: 0.7 , H: 0.99, tiFontEffect: 'bold', stiFontEffect: 'regular', auFontEffect: 'regular',
  options: {
    '1line': { tiA: {h: 0.1, from: 'top'}, stiA: {h: 0.04, from: 0.1}, auA: {h: 0.05, from: 'bottom'},tiPadding: 0.01, stiPadding: 0.01, auPadding: 0, maxTiNumLines: 1},
    '2line': {tiA: {h: 0.15, from: 'top'}, stiA: {h: 0.04, from: 0.15},auA: {h: 0.05, from: 'bottom'}, tiPadding: 0.01, stiPadding: 0.01, auPadding: 0, maxTiNumLines: 2},
  },
  clusterMap: {
    '16':'1line',
'83':'1line',
'41':'1line',
'8':'1line',
'38':'2line',
'56':'1line',
'51':'2line',
'17':'1line',
'90':'1line',
'12':'2line',
'24':'1line',
'31':'1line',
'9':'1line',
'98':'1line',
'80':'2line',
'44':'2line',
'28':'2line',
'46':'2line',
'78':'1line',
'82':'2line',
'2':'2line',
'76':'2line',
'49':'1line',
'47':'1line',
'96':'2line',
'62':'2line',
'87':'2line',
'30':'2line',
'3':'1line',
'66':'2line',
'91':'2line',
'54':'2line',
'21':'2line',
'60':'2line',
'11':'2line',
'5':'2line',
'70':'1line',
'67':'2line',
'64':'1line',
'81':'2line',
'77':'1line',
'69':'2line',
'1':'2line',
'84':'2line',
'13':'2line',
'10':'2line',
'59':'2line',
'15':'2line',
'7':'1line',
'6':'2line',
'45':'2line',
'68':'2line',
'35':'1line',
'73':'2line',
'50':'1line',
'26':'1line',
'25':'2line',
'71':'1line',
'19':'2line',
'72':'2line',
'40':'1line',
'43':'2line',
'36':'2line',
'75':'2line',
'52':'2line',
'32':'1line',
'57':'2line',
'74':'1line',
'99':'1line',
'23':'2line',
'0':'2line',
'86':'1line',
'34':'1line',
'4':'2line',
'14':'1line',
'48':'2line',
'18':'2line',
'65':'2line',
'95':'1line',
'85':'2line',
'79':'2line',
'58':'2line',
'27':'1line',
'97':'2line',
'92':'1line',
'42':'2line',
'22':'2line',
'55':'2line',
'39':'2line',
'93':'2line',
'88':'2line',
'29':'2line',
'33':'2line',
'37':'2line',
'20':'2line',
'53':'2line',
'89':'2line',
'61':'2line',
'63':'2line',
'94':'1line',
  },
},
},
];
//console.log(cluster);
//const clusterLayouts=clusterLayoutMap[cluster];//layouts[19];//Math.floor(Math.random()*layouts.length)];
//console.log(clusterLayouts);
//var layout=clusterLayouts[Math.floor(Math.random()*clusterLayouts.length)];
//console.log(layout);
var layoutDetail=layoutLibraries[Math.floor(Math.random()*layoutLibraries.length)].layoutDetail;
//console.log(layoutDetail);
var layoutOption=layoutDetail.clusterMap[cluster]; 
var layoutSizing=layoutDetail.options[layoutOption];
//console.log(layoutSizing);
//var area=layoutDetail.areas[Math.floor(Math.random()*layout.areas.length)];





design.tiAlign=layoutDetail.align;
design.tiDirection=layoutDetail.tiD;

design.stiAlign=layoutDetail.align;
design.stiDirection=layoutDetail.stiD;

design.auAlign=layoutDetail.align;
design.auDirection=layoutDetail.auD;

design.tiCase=layoutDetail.tiCase;
design.stiCase=layoutDetail.stiCase;
design.auCase=layoutDetail.auCase;




design.hPaddingStart=0;
design.hPaddingEnd=0;
design.hPaddingTop=0;
design.hPaddingBottom=0;

/*

design.tiPaddingStart=0.01;
if (layout.tiPaddingEnd) { design.tiPaddingEnd=layout.tiPaddingEnd; } else {design.tiPaddingEnd=0;}
design.tiPaddingTop=0.01;
design.tiPaddingBottom=0.01;

if (layout.stiPaddingStart) { design.stiPaddingStart=layout.stiPaddingStart; } else {design.stiPaddingStart=0.01;}
design.stiPaddingEnd=0.01;
design.stiPaddingTop=0.01;
design.stiPaddingBottom=0.01;

design.auPaddingStart=0;
design.auPaddingEnd=0;
design.auPaddingTop=0;
design.auPaddingBottom=0;

*/

//boxLayout.id=layout.id;



//var wHs=combineArrays(widths, heights);
//var tiA=area.H*area.W*area.tiA.area;
//var stiA=area.H*area.W*area.stiA.area;
//var auA=area.H*area.W*area.auA.area;



var H=layoutDetail.H;//selectedWH.H;
if (layoutDetail.align=='center') {
  var Y=(1-H)/2;  
} else {
  var Y=(1-H)/2;
}

var W=layoutDetail.W;//selectedWH.W;
if (layoutDetail.align=='center') {
  var X=(1-W)/2;
} else  {
  var X=(0.95-W);
}
//console.log(W, H, X, Y);

//var splitRatio=tiA/(W*H);//splitRatios[Math.floor(Math.random()*splitRatios.length)];
//var boxes1=splitBox(W, H , X, Y, 0, splitRatio,0);

  //var splitRatio2=stiA/(W*H);//splitRatios2[Math.floor(Math.random()*splitRatios2.length)];
  //var boxes2=splitBox(W,H, X, Y+area.stiA.from, 0, splitRatio2,0);

  //var splitRatio3=auA/(W*H)//1-(auA/(boxes2.box2.W*boxes2.box2.H));//splitRatios3[Math.floor(Math.random()*splitRatios3.length)];
  //var boxes3=splitBox(W,H, X, Y+area.auA.from, 0, splitRatio3,0);

  
  boxLayout.ti=getBoxCoords(W, H, X, Y, layoutSizing.tiA.from, layoutSizing.tiA.h);//boxes1.box1;//boxes[layout.tiBox];//boxes1.box1;
  boxLayout.sti=getBoxCoords(W, H, X, Y, layoutSizing.stiA.from, layoutSizing.stiA.h);//boxes2.box1;//boxes[layout.stiBox];//boxes3.box1;
  boxLayout.au=getBoxCoords(W,H,X,Y,layoutSizing.auA.from, layoutSizing.auA.h);//boxes3.box1;//boxes[layout.auBox];//boxes4.box2;

//var splitRatio=splitRatios[Math.floor(Math.random()*splitRatios.length)];
//var boxes4=splitBox(boxes3.box2.W,boxes3.box2.H, boxes3.box2.X, boxes3.box2.Y, Math.random()*1 > 0 ? 0 : 1, splitRatio,layout.reverse);
//var boxes5=splitBox(boxes4.box2.W,boxes4.box2.H, boxes4.box2.X, boxes4.box2.Y, Math.random()*1 > 0 ? 0 : 1, splitRatio);




design.maxTiNumLines=layoutSizing.maxTiNumLines;

 const fontCombo=fontCombos[Math.floor(Math.random()*fontCombos.length)];
 design.tiFont=fontCombo.tiFont;
 design.stiFont=fontCombo.stiFont;
 design.suFont=fontCombo.auFont;

  //design.tiAlign=boxLayout.ti.align;//textAlignments[Math.floor(Math.random()*textAlignments.length)];
  //design.stiAlign=boxLayout.sti.align;
  //design.auAlign='right';//boxLayout.au.align;
  design.tiFontEffect=layoutDetail.tiFontEffect;//'bold';//'regular';//'bold';//fontEffects[Math.floor(Math.random()*fontEffects.length)];


  //design.tiFontSize=tiFontSizes[Math.floor(Math.random()*tiFontSizes.length)]
  design.stiFontEffect=layoutDetail.stiFontEffect;//'regular';//fontEffects[Math.floor(Math.random()*fontEffects.length)];
  //design.stiFontSize=stiFontSizes[Math.floor(Math.random()*stiFontSizes.length)]
  design.auFontEffect=layoutDetail.auFontEffect;//fontEffects[Math.floor(Math.random()*fontEffects.length)];
  //design.auFontSize=auFontSizes[Math.floor(Math.random()*auFontSizes.length)]

  design.boxLayoutId=Math.random();//W+H+tiA+stiA+auA+layout.id;//boxLayout.id;
  //design.p=boxLayout.p;

 


  design.tiX=Math.floor(boxLayout.ti.X*design.canvasWidth);//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.ti.X*design.canvasWidth);
  //const tiYIndex=Math.floor(Math.random()*ys.length);
  design.tiY=Math.floor(boxLayout.ti.Y*design.canvasHeight);//ys[tiYIndex]*design.canvasHeight;//Math.floor(boxLayout.ti.Y*design.canvasHeight);
  //design.tiDirection=boxLayout.ti.direction;//'middle';//boxLayout.ti.direction;
  design.tiWidth=Math.floor(boxLayout.ti.W*design.canvasWidth);//design.canvasWidth-design.tiX;//Math.floor(boxLayout.ti.W*design.canvasWidth);
  design.tiHeight=Math.floor(boxLayout.ti.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.ti.H*design.canvasHeight);
  design.tiX+=Math.floor(layoutSizing.tiPadding*design.tiWidth);//design.p/2*design.tiWidth;
  design.tiY+=Math.floor(layoutSizing.tiPadding*design.tiHeight);//design.p/2*design.tiHeight;
  design.tiWidth-=Math.floor((layoutSizing.tiPadding+layoutSizing.tiPadding)*design.tiWidth);//design.p*design.tiWidth;
  design.tiHeight-=Math.floor((layoutSizing.tiPadding+layoutSizing.tiPadding)*design.tiHeight);//design.p*design.tiHeight;



  design.stiX=Math.floor(boxLayout.sti.X*design.canvasWidth);//design.tiX;//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.sti.X*design.canvasWidth);
  //ys.splice(tiYIndex,1);
  //const stiYIndex=Math.floor(Math.random()*ys.length);
  design.stiY=Math.floor(boxLayout.sti.Y*design.canvasHeight);//ys[stiYIndex]*design.canvasHeight;//Math.floor(boxLayout.sti.Y*design.canvasHeight);
  //design.stiDirection=boxLayout.sti.direction;//'middle';//boxLayout.sti.direction;
  design.stiWidth=Math.floor(boxLayout.sti.W*design.canvasWidth);//design.canvasWidth-design.stiX;//Math.floor(boxLayout.sti.W*design.canvasWidth);
  design.stiHeight=Math.floor(boxLayout.sti.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.sti.H*design.canvasHeight);
  design.stiX+=Math.floor(layoutSizing.stiPadding*design.stiWidth);//design.p/2*design.stiWidth;
  design.stiY+=Math.floor(layoutSizing.stiPadding*design.stiHeight);//design.p/2*design.stiHeight;
  design.stiWidth-=Math.floor((layoutSizing.stiPadding+layoutSizing.stiPadding)*design.stiWidth);//design.p*design.stiWidth;
  design.stiHeight-=Math.floor((layoutSizing.stiPadding+layoutSizing.stiPadding)*design.stiHeight);//design.p*design.stiHeight;

  //ys.splice(stiYIndex,1);

  design.auX=Math.floor(boxLayout.au.X*design.canvasWidth);//design.tiX;//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.au.X*design.canvasWidth);
  design.auY=Math.floor(boxLayout.au.Y*design.canvasHeight);//ys[Math.floor(Math.random()*ys.length)]*design.canvasHeight;//Math.floor(boxLayout.au.Y*design.canvasHeight);
  //design.auDirection='up';//boxLayout.au.direction;//'middle';//boxLayout.au.direction;
  design.auWidth=Math.floor(boxLayout.au.W*design.canvasWidth);//design.canvasWidth-design.auX;//Math.floor(boxLayout.au.W*design.canvasWidth);
  design.auHeight=Math.floor(boxLayout.au.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.au.H*design.canvasHeight);
  design.auX+=Math.floor(layoutSizing.auPadding*design.auWidth);//design.p/2*design.stiWidth;
  design.auY+=Math.floor(layoutSizing.auPadding*design.auHeight);//design.p/2*design.stiHeight;
  design.auWidth-=Math.floor((layoutSizing.auPadding+layoutSizing.auPadding)*design.auWidth);//design.p*design.stiWidth;
  design.auHeight-=Math.floor((layoutSizing.auPadding+layoutSizing.auPadding)*design.auHeight);


  design.label='bad';


 
  if (design.tiCase==='upper') design.ti = title.title.trim().toUpperCase();
  if (design.tiCase==='lower') design.ti = title.title.trim().toLowerCase();

  if (design.stiCase==='upper') design.sti = title.subtitle.trim().toUpperCase();
  if (design.stiCase==='lower') design.sti = title.subtitle.trim().toLowerCase();

  if (design.auCase==='upper') design.au = title.au.trim().toUpperCase();
  if (design.auCase==='lower') design.au = title.au.trim().toLowerCase();

  [design.tiMinWordLength, design.tiMaxWordLength] = getMinMaxLen(design.ti);
  [design.stiMinWordLength, design.stiMaxWordLength] = getMinMaxLen(design.sti);
  [design.auMinWordLength, design.auMaxWordLength] = getMinMaxLen(design.au);

  design.tiNumWords=design.ti.split(' ').length;
  design.stiNumWords=design.sti.split(' ').length;
  design.auNumWords=design.au.split(' ').length;

  design.tiLength=design.ti.length;
  design.stiLength=design.sti.length;
  design.auLength=design.au.length;

  if (design.stiLength ==0) {
  design.stiX=0;
  design.stiY=0;
  design.stiWidth=0;
  design.stiHeight=0;
  design.stiAlign='NA';
  design.stiDirection='NA';
  design.stiFontSize=0;
  }


  if (design.auLength == 0 ) {
  design.auX=0;
  design.auY=0;
  design.auWidth=0;
  design.auHeight=0;
  design.auAlign='NA';
  design.auDirection='NA';
  design.auFontSize=0;
  }
  [design.au, design.numAuthors] = getNumAuthors(design.au);

  return design;

}

